import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

const Materials = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="any img" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3">
          Наиболее распространенные материалы для 3D печати
          </h2>
          <a href='#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <h3 className='display-5 fw-bold lh-1 mb-3'>ABS <span class="badge bg-secondary">Top</span></h3>
      <p className="lead">
      ABS – пожалуй самый распространенный, доступный, а так же  прочный, термостойкий, не токсичный инженерный материал. Практически все корпуса бытовой техники, некоторые детали автомобиля, механизмы выполнены из данного пластика. Рынок представлен большим количеством расцветок и производителей. Так как этот пластик легко растворяется в обычном ацетоне, то его обработка и ремонт не вызовет особых трудностей. Еще очень большим достоинством растворимости ABS в ацетоне – его надежная склейка (химическая сварка), можно печатать бесконечно огромные детали по частям и соединять их воедино с помощью растворителя. Но есть и минусы, к которым можно отнести не лучшую спекаемость слоев и усадку в процессе остывания детали. Проблема решается использованием в печати более дорогого оборудования. Если вам нужна долговечная и надежная деталь, эксплуатируемая в жестких условиях, то этот пластик будет хорошим выбором.
      </p>
      <h3 className='display-5 fw-bold lh-1 mb-3'>PLA <span class="badge bg-secondary">NEW</span></h3>
      <p className='lead'>
      PLA – не менее распространенный и наверное самый популярный материал данной технологии печати. Жесткий, твердый не токсичный (сырьем для производства является кукуруза), но в тоже время довольно хрупкий и легкоплавкий материал (размягчается при 55-65 градусах Цельсия).  Главным его плюсом можно считать его неприхотливость при печати, поэтому отлично подходит для домашней не профессиональной печати, а так же печати художественных изделий, опытных образцов и мастер-моделей.
      </p>
      <h3 className='display-5 fw-bold lh-1 mb-3'>PETG <span class="badge bg-secondary">OPTIMA</span></h3>
      <p className='lead'>
      PETG – в FDM печати этот материал позиционировался как главная замена ABS и PLA, так как вобрал в себе наилучшее от обоих, простоту в использовании и механическую устойчивость, но все не так просто. Материал действительно имеет отличные характеристики, но не так легок в использовании как PLA да и не всегда он заменит ABS, скорее это компромиссное решение. К главным плюсам PETG можно отнести его ударопрочность, химическую стойкость, стойкость к ультрафиолетовому излучению и довольно сильную спекаемость слоев. PETG отличный материал, минусов у него меньше чем плюсов, поэтому он действительно может во многих случаях заменить PLA и ABS. Он отлично подойдет для печати тех же корпусов, но более ударопрочных, например корпуса квадрокоптера или различных радиоуправляемых моделей, а также деталей, которые будут эксплуатироваться на открытом воздухе. 
      </p>
      <h3 className='display-5 fw-bold lh-1 mb-3'>PA 6 (PA 12) <span class="badge bg-secondary">STRONG</span></h3>
      <p className='lead'>
      PA 6 (PA 12), тот же нейлон – самый прочный не композитный материал применяемый в печати. Сложность в использовании и его стоимость – это, пожалуй, все очевидные минусы данного материала. Спекаемость его слоев отличная, до 80-85% прочности на разрыв!
      Его механическая устойчивость к истиранию, динамическим нагрузкам, а так же термостокость позволяет его использовать в сильно нагруженных механизмах, зубчатых передачах (шестеренках), подшипниках скольжения, да да вы не ослышались, именно низкий коэффициент трения нейлона позволяет его использовать в качестве подшипника. Нейлон – отличный инженерный материал с множеством полезных свойств и при умелом использовании, аналогов ему нет.
      <h3 className='display-5 fw-bold lh-1 mb-3'>TPU<span class="badge bg-secondary">FLEX</span></h3>
      </p>
      <p className='lead'>
      TPU (и прочие flex) – гибкий, резиноподобный устойчивый к бензину, маслам и некоторым растворителям материал. Этот материал по своим свойствам не похож ни на один из предыдущих. Главным его плюсом является его гибкость и устойчивость к деформациям, а также очень сильная спекаемость слоев. Свойства данного материала позволяют использовать его при изготовлении различных прокладок, демпферов, шин радиоуправляемых моделей, ремней клиноременых передач, игрушек, браслетов, украшений ортопедических стелек и даже обуви. К минусам можно отнести его сложность использования и стоимость.
      </p>
      <h3 className='display-5 fw-bold lh-1 mb-3'>SBS<span class="badge bg-secondary">Transparency</span></h3>
      <p className='lead'>
      SBS – еще один  пластик с интересными свойствами, главным достоинством которого является его светопропускаемость (до 93%), а также отличная межслойная адгезия и гибкость, что отлично влияет на его прочность. Именно его светопропускаемость стала главным достоинством при печати различных плафонов для светильников, игрушек, посуды и даже медицинских приборов, что говорит о его нетоксичности.
      </p>
      <h5 id="materialsTable" className='display-7  lh-1 mb-3'>Ниже приведена краткая таблица основных материалов, их положительных и отрицательных качеств, а так же области применения.</h5>
    <table class="table table-hover articleTable">
  <thead>
    <tr>
      <th scope="col">материал</th>
      <th scope="col">Плюсы</th>
      <th scope="col">Минусы</th>
      <th scope="col">Применение</th>
    </tr>
  </thead>
  <tbody>
    <tr>
    <th scope="row">ABS+</th>
      <td>Прочность, термостойкость, хорошая обрабатываемость, легко и надежно клеится ацетоном.</td>
      <td>Усадка при печати, плохая межслойная адгезия (при печати на недорогих принтерах без термокамеры и на низких температурах экструдера).</td>
      <td>Корпуса, детали приборов, машин.</td>      
    </tr>
    <tr>
      <th scope="row">PLA</th>
      <td>Твердость, жесткость, отличная межслойная адгезия, нетоксичность, дешевизна, прост в эксплуатации, отличный результат печати даже на дешевых принтерах, не имея большого опыта в 3d печати.</td>
      <td>Хрупкость, плохая термостойкость, плохо обрабатывается механически, для химической пост обработки используется хлористый метилен.</td>
      <td>Печать макетов, опытных образцов, мастер-форм, художественная печать, печать игрушек, некоторой посуды.</td>
    </tr>
    <tr>
      <th scope="row">PTG</th>
      <td>Довольно прост в использовании, прочность, устойчив к ударным и динамическим нагрузкам, хорошая межслойная адгезия, химическая стойкость, стойкость к ультрафиолету.</td>
      <td>Может вызвать трудности в печати при недостаточном опыте, для химической пост обработки используется хлористый метилен.</td>
      <td>Корпуса и детали механизмов, радиомоделей, квадрокоптеров, некоторая посуда, декоративная печать.</td>      
    </tr>
    <tr>
      <th scope="row">PA</th>
      <td>Прочность, отличная межслойная адгезия, низкий коэффициент трения, в некоторых случаях упругость, стойкость к динамическим и ударным нагрузкам, неплохо механически обрабатывается.</td>
      <td>Сильная усадка, повышенные температуры печати, наличие термокамеры у принтера, стоимость.</td>
      <td>Сильно нагруженные механизмы, детали, зубчатые колеса (шестерни), подшипники трения.</td>
    </tr>
    <tr>
      <th scope="row">TPU</th>
      <td>Гибкость, упругость, стойкость к нефтепродуктам, отличная спекаемость слоев.</td>
      <td>Сложность печати, (наличие direct экструдера у принтера), стоимость.</td>
      <td>Печать прокладок, уплотнителей, демпферов, ремней передач, игрушек, браслетов (часы), клише печатей, ортопедические стельки, обувь.</td>
    </tr>
    <tr>
      <th scope="row">SBS</th>
      <td>Светопропускаемость, гибкость, хорошая межслойная адгезия, нетоксичность.</td>
      <td>Для получения наилучших прозрачных свойств требуется хлористый метилен.</td>
      <td>Плафоны светоприборов, художественная печать, некоторая посуда, вазы, мед.приборы, игрушки.</td>      
    </tr>    
  </tbody>
</table>
<h5 className='display-7  lh-1 mb-3'>Пользуясь данной таблицей вы можете сами примерно подобрать соответствующий вашим целям материал.</h5>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
    <FontAwesomeIcon icon={faHandHoldingDollar} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">Cкидка на серийную 3D-печати</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          При большом колличестве заказов на одинаковую или однотипную печать цены уменьшаются. (обсуждается индивидуально)
        </p>
      </div>
    </div>

  </main>
);

export default Materials;
