import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const SerialPrint = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="any img" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3">
            Серийная 3D печать
          </h2>
          <a href='/#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <p className="lead">
        При производстве множества копий одной детали целесообразно наладить серийное производство. Серийное производство пластиковых изделий производится на ТПA (термопластовый аппарат), литьем пластмассы под высоким давлением в специальные формы.
      </p>
      <p className='lead'>
        Себестоимость изготовления одной детали методом литья под давлением и 3d печати может различаться в несколько раз, а в некоторых случаях и в десятки раз.<strong> При многосерийном производстве (от 100 тысяч копий в месяц) экономически выгоднее изготовить пресс формы изделия</strong> и выполнить изготовление на ТПА, но <strong>при мелкосерийном производстве логичнее использовать технологию 3d печати</strong>, так как изготовление самой пресс-формы может доходить до десятков тысяч долларов и при малой серии будет нецелесообразно. Кампания <strong>3d masterhand</strong> предлагает услугу мелкосерийной печати в кротчайшие сроки.
      </p>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon' />
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>
  </div>
  </main>
);

export default SerialPrint;
