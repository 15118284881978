import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const Scanner = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="any img" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3">
            Выбор 3D принтера
          </h2>
          <a href='#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <p className="lead">
      Так как FDM печать получила наибольшее распространение в мире мы рассмотрим 3d принтера именно этой технологии.
      </p>
      <p className='lead'>
      В первую очередь нужно определиться с размером области построения  и какой материал будет использоваться для печати. Если с размером все понятно, то с выбором материала уже сложнее. Кроме многообразия цветовой гаммы материалы имеют различные химические и механические свойства, что в свою очередь требует и соответствующего оборудования. Если ваш выбор PLA или PETG, то открытого корпуса хватит, если печать планируется такими материалами как нейлон или ABS, то как минимум корпус должен быть закрытым, еще лучше с подогреваемой камерой.
      </p>
      <p className='lead'>
      Количество экструдеров. Наличие двух и более экструдеров подразумевает многоцветную печать, а также печать с легкоотделяемыми  поддержками. Вполне можно обойтись и одним экструдером, если многоцветная печать вас не интересует, но для печати некоторых сложных изделий с невозможностью отделения поддержек обычными методами очень может помочь второй экструдер с водорастворимым материалом.
      </p>
      <p className='lead'>
      Тип подачи материала. Тут все просто, есть два вида подающего механизма: direct и bowden. Bowden расположен на корпусе принтера и соединен тефлоновой трубкой с печатающей головкой, direct расположен на самой головке, что увеличивает ее массу. Главное преимущество bowden - печать на более высоких скоростях, direct – печать гибкими материалами, а также  более короткие ретракты, что в свою очередь сократит время печати.
      </p>
      <p className='lead'>
      Наличие датчика присутствия нити. При обрыве материала или его окончания, наличие этого датчика предотвратит сбой печати, автоматика поставит печать на паузу и после замены нити ее можно продолжить.
Защита от сбоев питания. Если по какой-либо причине произойдет сбой электропитания, то при наличии этой функции можно продолжить печать после его восстановления. Одним словом, если не хотите выкинуть кучу испорченного материала, а так же сберечь нервы и время, без этой функции не обойтись.

      </p>
      <p className='lead'>
      Кинематика. Если вы новичок и решили просто попробовать трехмерную печать, то хорошим выбором будет кинематика pruche или как ее еще называют «дрыгостол». Простота конструкции, относительная дешевизна и большая популярность – огромный плюс при выборе первого принтера. 

      </p>
      <p className='lead'>
      Delta. Принтеры с данной схемой могут печатать на высоких скоростях, но сложнее калибруются, а так же требуют хорошего качества комплектующих, что на прямую влияет на повышение стоимости принтера.

      </p>
      <p className='lead'>
      Cartesian. Данная схема будет немного лучше prucha за счет снижения массы движущихся частей, по стоимости примерно на том же уровне.

      </p>
      <p className='lead'>
      Схемы H-bot Core-XY г. Главным преимуществом является отличное качество и скорость печати этих кинематик. По стоимости на уровне Cartesian.
Ultimaker. Высокая скорость и качество печати, а так же высокая стоимость из повышенных требований к качеству деталей.


      </p>
      <p className='lead'>
      Наличие подогрева стола может быть не обязательным, но это сократит перечень используемых материалов. Если принтером будет пользоваться ребенок, то с точки зрения безопасности, лучше обойтись без подогрева.
      </p>
      <span className="badge rounded-pill">Итог:</span>
      <p className='lead'>
     Можно сказать: если вы делаете первые шаги в печати и вас интересует в основном печать макетов, фигурок и прочих моделей от которых не требуется хороших механических свойств, то можно ограничить свой выбор принтерами до 300 долларов.
      </p>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon' />
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>

  </main>
);

export default Scanner;
