import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

const Materials = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="any img" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3 text-center">
          3d печать карбоном
          </h2>
          <a href='#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <h3 className='display-5 fw-bold lh-1 mb-3'>Что такое карбон?</h3>
      <p className="lead">
      Это тот же филамент, но с добавками в виде углеродного волокна, где пластик является матрицей и служит связующей массой для углеродных волокон. Углерод улучшает качества пластика, делает его прочнее, более термостойким. Наиболее удачным сочитанием является нейлон + волокна углерода. И без того отличные качества нейлона улучшаются, а его недостати в виде усадки снижаются.
      </p>
      <p className='lead'>
      Для печати композитами потребуется стальное сопло или еще лучше сопло из закаленной стали, так как обычное латунное очень быстро расточится из-за абразивных свойств пластика. Так же некоторые композиты требуют повышенных температур печати, поэтому термобарьеры с PTFE трубкой могут стать ограничением в 260 градусов, что может повлиять на хорошую спекаемость слоев. Не будет лишней и пассивная термокамера, хотя некоторые композиты хорошо печатаются и без нее.
      </p>
      <p className='lead'>
      Еще одним из достоинств карбона является его хорошая обрабатываемость. 
      </p>
      <p className='lead'>
      Пластик наполненый карбоном - отличный выбор инженеров, так как сочитает в себе высокую прочность, точность рамеров и относительную простоту в использовании.
      </p>
    </div>
    <div id='solves' className="container col-xxl-10 py-5 solves">
    <FontAwesomeIcon icon={faHandHoldingDollar} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">Cкидка на серийную 3D-печати</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          При большом колличестве заказов на одинаковую или однотипную печать цены уменьшаются. (обсуждается индивидуально)
        </p>
      </div>
    </div>

  </main>
);

export default Materials;
