import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const ModelOptimization = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="any img" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-5 fw-bold lh-1 mb-3">
            Оптимизация 3d моделей под FDM печать
          </h2>
          <a href='/#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <p className="lead">
        С помощью <strong>3d печати</strong> можно изготовить объект практически любой формы и сложности, все упирается лишь в фантазию, однако существуют некоторые ограничения.
      </p>
      <p className='lead'>
        Так как в FDM печати деталь формируется послойно, каждый последующий слой накладывается на предыдущий ровно (вертикальные стенки) или со смещением (стенки с наклоном). Хороший <strong>принтер способен печатать детали с углом наклона до 60 градусов</strong>, но сложные формы модели могут содержать в себе и наклоны до 90 градусов.
      </p>
      <p className="lead">
        Для решения данной проблемы применяются <strong>охлаждение</strong> (обдув модели воздухом) или поддержки, но оба варианта имеют как плюсы, так и минусы. Обдув негативно влияет на спекание слоев (прочность детали), а поддержки портят внешний вид распечаток. Что бы не использовать обдув и поддержки, нужно заранее моделировать деталь так, чтоб в ней свести к минимуму количество нависающих поверхностей (нависания не более 60 градусов).
      </p>
      <p className='lead'>
         Если от распечатки требуется <strong>максимальная прочность</strong>, то при моделировании и размещении на столе принтера нужно учесть в каком направлении будет приложено усилие, чтобы нагрузка распределялась вдоль слоев, а не поперек.
          Если в детали (распечатке) важны <strong> внешний вид и прочность</strong>, можно делать их составными, учитывая при моделировании места крепления частей.
      </p>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon' />
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>
  </div>
  </main>
);

export default ModelOptimization;
